export function useAuthTelegramScript() {
  return useScript(
    {
      src: 'https://telegram.org/js/telegram-widget.js?22',
      async: true,
      crossorigin: false,
      referrerpolicy: false,
    },
    {
      use: () => globalThis.Telegram?.Login,
    },
  )
}
